<template>
    <div class="tabbar">
        <el-menu default-active="1-4-1" class="el-menu-vertical-demo" :style="!showtabbar? 'height:3.72vw':''" collapse active-background-color="#ffffff">
             <transition name="fade">
        <div class="showtabbar" v-show="showtabbar">
            <el-menu-item index="2">
                <div class="guanwang">
                    <img class="guanwangoImg" src="../assets/image/guanwango.png" alt="" @click="gotoGuanwang">
                    <img class="guanwangImg" src="../assets/image/guanwang.png" alt="" @click="gotoGuanwang">
                    官网
                    </div>
                <span slot="title">点击进入官网</span>
            </el-menu-item>
            <el-menu-item index="2">
                <div class="app">
                    <img class="appoImg" src="../assets/image/appo.png" alt="">
                    <img class="appImg" src="../assets/image/app.png" alt="">
                    App</div>
                <span slot="title">
                    <span>
                        <img class="tabbarImg" src="../assets/image/appCode.png" alt="">
                        <div class="tabbarItem">MLabs App下载</div>   
                    </span>
                </span>
            </el-menu-item>
            <el-menu-item index="3">
                <div class="xiaochengxu"><img class="xiaochengxuoImg" src="../assets/image/xiaochengxuo.png" alt="">
                <img class="xiaochengxuImg" src="../assets/image/xiaochengxu.png" alt="">小程序</div>
                <span slot="title">
                    <span class="item">
                        <img class="tabbarImg" src="../assets/image/xiaochengxuCode.jpg" alt="">
                        <div class="tabbarItem">Moolsnet小程序</div>   
                    </span>
                    <span class="item">
                        <img class="tabbarImg" src="../assets/image/shiyanshianquanCode.jpg" alt="">
                        <div class="tabbarItem">实验室安全教育小程序</div>   
                    </span>
                </span>
            </el-menu-item>
            <el-menu-item index="4">
                <div class="gongzhonghao"><img class="gongzhonghaooImg" src="../assets/image/gongzhonghaoo.png" alt="">
                <img class="gongzhonghaoImg" src="../assets/image/gongzhonghao.png" alt="">公众号</div>
                <span slot="title">
                    <span>
                        <img class="tabbarImg" src="../assets/image/gongzhonghaoCode.png" alt="">
                        <div class="tabbarItem">moolsnet公众号</div>   
                    </span> 
                </span>
            </el-menu-item>
            <el-menu-item index="5">
                <div class="kefu"><img class="kefuoImg" src="../assets/image/kefuo.png" alt="">
                <img class="kefuImg" src="../assets/image/kefu.png" alt="">客服</div>
                <span slot="title">
                    <span>
                        <img class="tabbarImg" src="../assets/image/kefuCode.png" alt="">
                        <div class="tabbarItem">客服微信(8:30-18:00)</div>   
                    </span>
                </span>
            </el-menu-item>
        </div>
  </transition>
        <el-menu-item index="6" @click="handleShowtabbar">
            <div class="shouqi" v-show="showtabbar">
                <img class="shouqiImg" src="../assets/image/shouqi.png" alt="" >
                <img class="shouqioImg" src="../assets/image/shouqio.png" alt="" >
                收起</div>
            <div class="zhankai" v-show="!showtabbar">
                <img class="zhankaiImg" src="../assets/image/zhankai.png" alt="" >
                <img class="zhankaioImg" src="../assets/image/zhankaio.png" alt="" >
                展开</div>
            <span slot="title">{{showtabbar? '点击收起':'点击展开'}}</span>
        </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
import { Message } from "element-ui"; 
import API from '@/api/components/DownLoad'
export default {
    data() {
        return {
            showtabbar:true
        };
    },
    created() {
      
    },
    methods: {
        gotoGuanwang()
        {
            window.open('https://www.mools.net')
        },
        handleShowtabbar()
        {
            this.showtabbar = !this.showtabbar
        }
    }
};
</script>

<style lang="scss" scope>
.tabbar .fade-enter-active, .fade-leave-active {
  transition: all 0s;
}
.tabbar .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
//   transform: translateY(100px);
}
.tabbar .el-menu-item{
    font: normal normal normal 0.625vw Source Han Sans CN !important;
    letter-spacing: 0px;
    color: #707070;
    }
.tabbar .is-active{
     border-left:0 !important;
}
.el-menu--collapse{
    width: 2.6042vw;
}
.tabbar .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 12vw;
    height: 40vh;
  }
.tabbar{
    position: fixed;
    z-index: 999999;
    // padding-top: 1rem;
    bottom: 5.3125vw;
    right: 1.0625vw;
    box-shadow: 0vw 0vw 0.31875vw #0000003D;
    border-radius: 0.53125vw;
    overflow: hidden;
}
.tabbarImg{
    width: 7vw;
    height: 7vw;
}
.tabbar .el-menu{
    // width: 3vw;
    height: 20.08125vw;
    border-right: solid 0.053125vw #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #FFF;
    padding-top:0.85vw;
}
.item{
    display: inline-block;
    margin: 0 0.53125vw;
}
.is-dark{
    background-color: #ffffff  !important;
    box-shadow: 0.053125vw 0.053125vw 0.159375vw #00000029 !important;
    border-radius: 0.53125vw !important;
    color:#0D5570 !important
}
.tabbar img{
    width: 0.95625vw;
    height: 0.95625vw;
    margin: 0 auto 0.425vw ;
    display: block;
}
.tabbar .el-menu-item, .tabbar .el-submenu__title {
    padding: 1.0625vw 0;
    height: 3.24vw;
    line-height: inherit;
    list-style: none;
    position: relative;
    white-space: nowrap;
}
.tabbar .el-tooltip{
    text-align: center;
    padding: 0 !important;
}
.tabbarItem{
    text-align: center;
        color: #0D5570;
        margin-top: 0.27vw;
}
.tabbar .el-menu-vertical-demo .el-menu-item{
    padding-left: 0 !important;
}
.tabbar .el-menu-item:hover{
    background-color: #FFFFFF !important;
}
.guanwangImg,.appImg,.xiaochengxuImg,.gongzhonghaoImg,.kefuImg,.shouqiImg,.zhankaiImg{
    display: none !important;
}
.guanwang:hover > .guanwangoImg,.app:hover > .appoImg,.xiaochengxu:hover > .xiaochengxuoImg,.gongzhonghao:hover > .gongzhonghaooImg,.kefu:hover > .kefuoImg,.shouqi:hover > .shouqioImg,.zhankai:hover > .zhankaioImg{
    display: none;
}
.guanwang:hover > .guanwangImg,.app:hover > .appImg,.xiaochengxu:hover > .xiaochengxuImg,.gongzhonghao:hover > .gongzhonghaoImg,.kefu:hover > .kefuImg,.shouqi:hover > .shouqiImg,.zhankai:hover > .zhankaiImg{
    display: block !important;
}
.guanwang:hover,.app:hover,.xiaochengxu:hover,.gongzhonghao:hover,.kefu:hover,.shouqi:hover,.zhankai:hover{
    color: #0d5570 !important;
}
// img{
//     width: 100%;
//     height: 100%;
// }
</style>
